const Footer = () => {
    return (
        <>
            <div className='space' />
            <footer
                className='d-flex align-items-center justify-content-center mt-2 w-100'
                style={{
                    minHeight: '50px',
                    backgroundColor: '#191919',
                    color: 'white',
                    fontWeight: '200'
                }}>
                &copy;&nbsp;by Alexander Wiebe
            </footer>
        </>
    )
}

export default Footer
