import { Container } from 'react-bootstrap'
import { AuthProvider } from '../contexts/AuthContext'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Header from './Header'
import Home from './Home'
import Login from './Login'
import PrivateRoute from './PrivateRoute'
import ForgotPassword from './ForgotPassword'
import Footer from './Footer'
import Quiz from './Quiz'

function App() {
    return (
        <Router>
            <AuthProvider>
                <Container
                    className='d-flex flex-column'
                    style={{
                        minHeight: '100vh',
                        maxWidth: '100%',
                        paddingRight: '0',
                        paddingLeft: '0'
                    }}>
                    <Header />
                    <Container className='d-flex flex-column mt-3 align-items-center justify-content-center'>
                        <Switch>
                            <PrivateRoute exact path='/' component={Home} />
                            <PrivateRoute path='/quiz/:dbCollection' component={Quiz} />
                            <Route path='/login' component={Login} />
                            <Route path='/forgot-password' component={ForgotPassword} />
                        </Switch>
                    </Container>
                    <Footer />
                </Container>
            </AuthProvider>
        </Router>
    )
}

export default App
