import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import PropTypes from 'prop-types'

const QuizResult = ({ questions, toggleQuizResult }) => {
    const [result, setResult] = useState()

    useEffect(() => {
        let numberCorrect = 0
        questions.forEach((element) => {
            const selectedAnswers = getSelectedAnswers(element).toString()
            element.selectedAnswers = selectedAnswers
            if (element.correctAnswers === selectedAnswers) {
                numberCorrect++
                element.isAnswerCorrect = true
            } else {
                element.isAnswerCorrect = false
            }
        })

        setResult(((100 / questions.length) * numberCorrect).toFixed(2) + '%')
    }, [questions])

    const getSelectedAnswers = (question) => {
        const selectedAnswers = []

        if (question.answer_1.selected) {
            selectedAnswers.push('answer_1')
        }

        if (question.answer_2.selected) {
            selectedAnswers.push('answer_2')
        }

        if (question.answer_3.selected) {
            selectedAnswers.push('answer_3')
        }

        if (question.answer_4.selected) {
            selectedAnswers.push('answer_4')
        }

        return selectedAnswers
    }

    return (
        <>
            {result && (
                <div>
                    <h2>
                        Result: {result} |{' '}
                        <Link
                            to='#'
                            onClick={(e) => {
                                e.preventDefault()
                                toggleQuizResult()
                            }}>
                            Back to Quiz
                        </Link>
                    </h2>
                    <section className='terminal-container terminal-fixed-top'>
                        <header className='terminal'>
                            <span className='button red' />
                            <span className='button yellow' />
                            <span className='button green' />
                            <span className='space' />
                        </header>
                        <div className='terminal-home'>
                            {questions &&
                                questions.map((entry) => (
                                    <div key={entry.id}>
                                        {entry.isAnswerCorrect && (
                                            <div className='result-row' style={{ color: 'green' }}>
                                                <span className='result-col-1'>ID {entry.id}</span>
                                                <span className='result-col-2'>
                                                    Answer was: CORRECT.
                                                </span>
                                                <span className='result-col-3'>
                                                    Correct answer: {entry.correctAnswers}
                                                </span>
                                                <span className='result-col-4'>
                                                    Your answer: {entry.selectedAnswers}
                                                </span>
                                            </div>
                                        )}
                                        {!entry.isAnswerCorrect && (
                                            <div className='result-row' style={{ color: 'red' }}>
                                                <span className='result-col-1'>ID {entry.id}</span>
                                                <span className='result-col-2'>
                                                    Answer was: WRONG.
                                                </span>
                                                <span className='result-col-3'>
                                                    Correct answer: {entry.correctAnswers}
                                                </span>
                                                <span className='result-col-4'>
                                                    Your answer: {entry.selectedAnswers}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            <div className='question-buttons'>
                                <Button
                                    variant='light'
                                    style={{ fontWeight: '600' }}
                                    onClick={() => toggleQuizResult()}>
                                    Back to Quiz
                                </Button>
                            </div>
                        </div>
                    </section>
                </div>
            )}
        </>
    )
}

QuizResult.propTypes = {
    questions: PropTypes.any,
    toggleQuizResult: PropTypes.any
}

export default QuizResult
