import { Route, Redirect } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import PropTypes from 'prop-types'

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { currentUser } = useAuth()

    return (
        <Route
            {...rest}
            render={(props) => {
                return currentUser ? <Component {...props} /> : <Redirect to='/login' />
            }}
        />
    )
}

PrivateRoute.propTypes = {
    component: PropTypes.any
}

export default PrivateRoute
