import { useState, useEffect } from 'react'
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'

const Question = ({
    currentQuestion,
    totalNumberOfQuestions,
    next,
    previous,
    toggleQuizResult
}) => {
    const [reload, setReload] = useState(true)

    // Rerender question if a checkbox state gets changed
    useEffect(() => {}, [reload])

    const onChange = (answerNumber) => {
        if (answerNumber === 'answer_1') {
            currentQuestion.answer_1.selected = !currentQuestion.answer_1.selected
        } else if (answerNumber === 'answer_2') {
            currentQuestion.answer_2.selected = !currentQuestion.answer_2.selected
        } else if (answerNumber === 'answer_3') {
            currentQuestion.answer_3.selected = !currentQuestion.answer_3.selected
        } else if (answerNumber === 'answer_4') {
            currentQuestion.answer_4.selected = !currentQuestion.answer_4.selected
        }
        setReload(!reload)
    }

    const renderCorrectAnswerTooltip = (props) => (
        <Tooltip id='icon-tooltip' {...props}>
            {'Correct Answer: ' + currentQuestion.correctAnswers.replace(/answer_/g, '')}
        </Tooltip>
    )

    return (
        <div>
            <section className='terminal-container terminal-fixed-top'>
                <header className='terminal'>
                    <span className='button red' />
                    <span className='button yellow' />
                    <span className='button green' />
                    <span className='space'>
                        Question {currentQuestion.id} of {totalNumberOfQuestions}
                    </span>
                    <div className='info-link' style={{ fontSize: 20 }}>
                        <OverlayTrigger
                            placement='right'
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderCorrectAnswerTooltip}>
                            <div>
                                <FontAwesomeIcon icon={faInfoCircle} />
                            </div>
                        </OverlayTrigger>
                    </div>
                </header>

                <div className='terminal-home'>
                    <h3>
                        Question {currentQuestion.id}: {currentQuestion.question}
                    </h3>
                    <p className='console'>
                        <input
                            type='checkbox'
                            id={currentQuestion.id + '_a1'}
                            name={currentQuestion.id + '_a1'}
                            onChange={() => onChange('answer_1')}
                            checked={currentQuestion.answer_1.selected}
                            style={{ marginTop: '5px' }}
                        />
                        <span className='answer'>{currentQuestion.answer_1.value}</span>
                    </p>
                    <p className='console'>
                        <input
                            type='checkbox'
                            id={currentQuestion.id + '_a2'}
                            name={currentQuestion.id + '_a2'}
                            onChange={() => onChange('answer_2')}
                            checked={currentQuestion.answer_2.selected}
                            style={{ marginTop: '5px' }}
                        />
                        <span className='answer'>{currentQuestion.answer_2.value}</span>
                    </p>
                    <p className='console'>
                        <input
                            type='checkbox'
                            id={currentQuestion.id + '_a3'}
                            name={currentQuestion.id + '_a3'}
                            onChange={() => onChange('answer_3')}
                            checked={currentQuestion.answer_3.selected}
                            style={{ marginTop: '5px' }}
                        />
                        <span className='answer'>{currentQuestion.answer_3.value}</span>
                    </p>
                    <p className='console'>
                        <input
                            type='checkbox'
                            id={currentQuestion.id + '_a4'}
                            name={currentQuestion.id + '_a4'}
                            onChange={() => onChange('answer_4')}
                            checked={currentQuestion.answer_4.selected}
                            style={{ marginTop: '5px' }}
                        />
                        <span className='answer'>{currentQuestion.answer_4.value}</span>
                    </p>
                    <div className='question-buttons'>
                        <Button onClick={() => previous()}>Previous</Button>
                        <span className='space'>
                            <Button onClick={() => toggleQuizResult()}>Result</Button>
                        </span>
                        <Button onClick={() => next()}>Next</Button>
                    </div>
                </div>
            </section>
        </div>
    )
}

Question.propTypes = {
    currentQuestion: PropTypes.any,
    totalNumberOfQuestions: PropTypes.any,
    next: PropTypes.any,
    previous: PropTypes.any,
    toggleQuizResult: PropTypes.any
}

export default Question
