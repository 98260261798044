import { useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { useParams } from 'react-router'
import useFetch from '../hocks/useFetch'
import Question from './Question'
import QuizResult from './QuizResult'
import PropTypes from 'prop-types'

const Quiz = (props) => {
    const { dbCollection } = useParams()

    const [title, setTitle] = useState()

    // Custom Hook
    const { data: questions, error, isLoading } = useFetch(dbCollection)

    const [currentQuestion, setCurrentQuestion] = useState()
    const [showResult, setShowResult] = useState()

    // Set currentQuestion whenever the variable 'questions' gets changed
    // Right now only after the initial fetch from the firebase db via useEffect -> fetchData()
    useEffect(() => {
        if (questions && questions.length > 0) {
            setCurrentQuestion(questions[0])
            if (props.location.state !== null && props.location.state !== undefined) {
                setTitle(props.location.state.title)
            }
        } else {
            setCurrentQuestion(null)
        }
    }, [questions, props.location.state])

    const next = () => {
        if (currentQuestion.id < questions.length) {
            setCurrentQuestion(questions[currentQuestion.id])
        }
    }

    const previous = () => {
        if (currentQuestion.id - 1 > 0) {
            const previousId = currentQuestion.id - 2
            setCurrentQuestion(questions[previousId])
        }
    }

    const toggleQuizResult = () => {
        setShowResult(!showResult)
    }

    return (
        <>
            <h2>{title}</h2>
            {error && <Alert variant='danger'>{error}</Alert>}
            {isLoading && <div>is Loading</div>}
            {!showResult && currentQuestion && (
                <Question
                    currentQuestion={currentQuestion}
                    totalNumberOfQuestions={questions.length}
                    next={next}
                    previous={previous}
                    toggleQuizResult={toggleQuizResult}
                />
            )}

            {showResult && questions && (
                <QuizResult questions={questions} toggleQuizResult={toggleQuizResult} />
            )}
        </>
    )
}

Quiz.propTypes = {
    location: PropTypes.any
}

export default Quiz
