import { useEffect, useState } from 'react'
import { db } from '../firebase'
import { collection, onSnapshot } from 'firebase/firestore'

const useFetch = (dbCollection) => {
    const [data, setData] = useState()
    const [error, setError] = useState()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setError('')
        setIsLoading(true)

        try {
            const unsubscribe = onSnapshot(
                collection(db, dbCollection),
                (snapshot) => {
                    const documents = []
                    snapshot.docs.forEach((doc) => {
                        documents.push(doc.data())
                    })

                    if (documents.length <= 0) {
                        setError(
                            'Could not receive data from the given database collection: ' +
                                dbCollection
                        )
                    }
                    setData(documents)
                    setIsLoading(false)
                },
                (error) => {
                    console.log('Exception fetchData: ' + error)
                    setError(
                        'Could not receive data from the given database collection: ' + dbCollection
                    )
                    setData(null)
                    setIsLoading(false)
                }
            )
            // CleanUp
            return unsubscribe
        } catch (e) {
            console.log('Exception fetchData: ' + e)
            setError('Could not receive data from database.')
            setData(null)
            setIsLoading(false)
        }
    }, [dbCollection])

    return { data, error, isLoading }
}

export default useFetch
