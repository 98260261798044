import { useState } from 'react'
import { useAuth } from '../contexts/AuthContext'
import { useHistory, Link } from 'react-router-dom'
import logo from '../assets/images/logo.svg'

const Header = () => {
    const title = 'Cloud-Dev-Team'
    const [error, setError] = useState('')
    const { currentUser, logout } = useAuth()
    const history = useHistory()

    function handleHomeLogoClick() {
        history.push('/')
    }

    async function handleLogout() {
        setError('')

        try {
            await logout()
            history.push('/login')
        } catch {
            setError('Failed to log out')
            console.log(error)
        }
    }

    return (
        <div
            className='d-flex align-items-center'
            style={{
                minHeight: '80px',
                padding: '0px 20px',
                color: 'white',
                fontWeight: '300',
                backgroundColor: '#191919'
            }}
            role='banner'>
            <img
                className='mx-3'
                width='55'
                alt='Cloud-Dev-Team Logo'
                src={logo}
                onClick={() => handleHomeLogoClick()}
                style={{ cursor: 'pointer' }}
            />
            <div className='flex-fill'>
                <h2>{title}</h2>
            </div>

            {currentUser && (
                <div className='d-flex header'>
                    <Link to='' onClick={handleLogout}>
                        Logout
                    </Link>
                </div>
            )}

            {!currentUser && (
                <div className='d-flex header'>
                    <Link to='/login'>Login</Link>
                </div>
            )}
        </div>
    )
}

export default Header
