import { Button, Card } from 'react-bootstrap'
import sapCcLogo from '../assets/images/sap_commerce_cloud_logo.svg'
import 'bootstrap/dist/css/bootstrap.min.css'
import { useHistory } from 'react-router-dom'

const Home = () => {
    const history = useHistory()

    const dashboardLinks = [
        {
            id: 1,
            imgSrc: { sapCcLogo },
            title: 'SAP CC Quiz - Core Developer',
            description:
                'Here you can find a sample SAP CC Quiz to prepare for the SAP CC Core Developer certification.',
            btnTxt: 'Go to Quiz',
            path: '/quiz/sap_cc_core_developer_questions'
        },
        {
            id: 2,
            imgSrc: { sapCcLogo },
            title: 'SAP CC Quiz - Business Analyst',
            description:
                'Here you can find a sample SAP CC Quiz to prepare for the SAP CC Business Analyst certification.',
            btnTxt: 'Go to Quiz',
            path: 'quiz/sap_ba_questions'
        }
    ]

    function handleDashboardCardClick(path, quizTitle) {
        history.push({
            pathname: path,
            state: { title: quizTitle }
        })
    }

    return (
        <>
            <h2 className='text-center mb-4'>Dashboard</h2>
            <div className='d-flex justify-content-center flex-wrap flex-row'>
                {dashboardLinks.map((entry) => (
                    <Card
                        key={entry.id}
                        className='d-flex align-items-center me-2 mb-3'
                        style={{ width: '18rem' }}>
                        <Card.Img
                            variant='top'
                            src={entry.imgSrc.sapCcLogo}
                            style={{ width: '100px' }}
                        />
                        <Card.Body>
                            <Card.Title
                                className='overflow-hidden text-center'
                                style={{ minHeight: '50px', maxHeight: '50px' }}>
                                {entry.title}
                            </Card.Title>
                            <Card.Text
                                className='overflow-hidden mb-2'
                                style={{ minHeight: '100px', maxHeight: '100px' }}>
                                {entry.description}
                            </Card.Text>
                            <div className='d-flex justify-content-center'>
                                <Button
                                    className='w-100'
                                    variant='primary'
                                    onClick={() =>
                                        handleDashboardCardClick(entry.path, entry.title)
                                    }>
                                    {entry.btnTxt}
                                </Button>
                            </div>
                        </Card.Body>
                    </Card>
                ))}
            </div>
        </>
    )
}

export default Home
